.map-gift-modal {
  position: absolute;
  width: 327px;
  /*height: 365px;*/
  z-index: 100000;
  background-color: #ffffff;
  top: 40px;
  right: 40px;
  transition: 500ms;
  opacity: 0;
  visibility: hidden;
}

.map-gift-modal-active {
  position: absolute;
  width: 327px;
  /*height: 365px;*/
  background-color: #ffffff;
  z-index: 100000;
  top: calc(50% - 182px);
  right: calc(50% - 163px);
  border-radius: 8px;
  transition: 500ms;
  opacity: 1;
  padding: 23px 31px 31px;
  display: flex;
  flex-direction: column;
  cursor: initial;
  visibility: initial;
}

.modal-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.modal-icon {
  width: 33px;
  height: 33px;
  margin-right: 17px;
}

.modal-title-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: rgba(75, 75, 75, 0.8);
  margin: 0;
}

.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.modal-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
}

.modal-confident {
  display: flex;
  align-items: center;
}

.modal-checkbox {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.modal-input {
  margin: 15px 0 0 0;
}

.modal-email {
  width: 100%;
  height: 40px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  padding: 12px 29px 9px 10px;
  margin: 15px 0 0 0;
}

.modal-email:focus {
  outline: none;
}

.modal-email::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.25);
}

.modal-form {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.modal-btn {
  margin-top: 17px;
}

.modal-custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.modal-custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.modal-custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #615FFB;
  border-radius: 2px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  transition: 500ms;
}

.modal-custom-checkbox:checked+label::before {
  border-color: #615FFB;
  background-color: #615FFB;
  background-size: 12px;
  background-image: url('../../../../../../src/assets/icon/mark.svg');
}

.modal-custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: #615FFB;
}

.modal-custom-checkbox:focus:not(:checked)+label::before {
  border-color: #D9D9D9;
}

@media (max-width: 380px) {
  .map-gift-modal-active {
    width: 280px;
    right: calc(50% - 140px);
    padding: 10px;
  }
}
