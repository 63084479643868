.container-earn {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.container-earn a {
    width: 100%;
    max-width: 323px;
}

.container-earn-title {
    font-weight: 700;
    font-size: 48px;
    color: #4B4B4B;
}

.container-earn-description {
    text-align: center;
    margin: 0;
    margin-bottom: 46px;
}

.container-earn-diagram {
    margin-top: 60px;
    margin-bottom: 120px;
}
.container-earn-diagram img {
    width: 100%;
}

@media screen and (max-width: 429px) {
    .container-earn {
        width: 100%;
    }

    .container-earn-title {
        font-size: 28px;
    }

    /*.container-earn-diagram-img {*/
    /*    width: 90%*/
    /*}*/
}