.header {
    background: #fff !important;
    z-index: 10;
    position: relative;
    border-bottom: 1px solid #c9c9c9;
    margin-bottom: 30px;
}
.header_wrapper {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background: transparent !important;
    z-index: 10;
}

.header-nav {
    display: flex;
    align-items: center;
}
.header-nav_desktop {
}
.header-nav_desktop .item {
    margin-left: 36px;
}
.header-nav_desktop .sub-menu {
    right: -2px;
}

.header-nav_desktop .sub-menu-item:last-child {
    margin-bottom: 0;
}
.header-nav_desktop .item {
    height: 24px;
}
.header-nav_desktop .item svg {
    width: 16px;
    height: 19px;
}
.header-nav_desktop .item:hover .sub-menu {
    display: flex;
    transition: 0.4s;
}

@media (max-width: 765px) {
    .header-nav_desktop {
        display: none;
    }
}
