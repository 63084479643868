/* .PublicOffers_item_title {
    font-size: 16px;
    font-weight: 700;
} */

.offer-number {
    font-weight: 700;
    font-size: 16px;
}

.PublicOffers_item_read-more > img {
    padding: 10px;
    background: #615ffb;
    border-radius: 5px;
}
.PublicOffers_item_read-more {
    transition: 0.1s;
}
.PublicOffers_item_read-more:hover {
    outline: none;
}
.PublicOffers_item_read-more:active {
    opacity: 50%;
    transition: 0.1s;
}

.offers_menu {
    display: flex;
    justify-content: space-between;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.offers_menu {
}

.menu_item {
    position: relative;
    color: #4b4b4b;
    cursor: pointer;
    list-style: none;
}

.menu_item:after {
    display: block;
    position: absolute;
    left: 0;
    top: 32px;
    width: 0;
    height: 2px;
    background: #615ffb;
    content: "";
}

.menu_item:focus:after,
.menu_item:hover:after {
    width: 100%;
}

.menu_item:hover {
    color: #615ffb;
}

.menu_item-active {
    color: #615ffb;
}



.menu_item-active > .menu-svg polygon,
.menu_item-active > .menu-svg line,
.menu_item-active > .menu-svg polyline,
.menu_item-active > .menu-svg path,
.menu_item-active > .menu-svg mesh,
.menu_item-active > .menu-svg rect,
.menu_item-active > .menu-svg circle,
.menu_item-active > .menu-svg ellipse,
.menu_item-active > .menu-svg stroke {
    fill: #d6414b;
    color: #d6414b;
}
.offer-svg {
    transition: 0.1s;
}
.offer-svg:hover {
    transform: scale(1.3);
}
.offer-svg:active {
    opacity: 0.5;
}
.menu-svg {
    min-width: 24px;
    transition: 0.1s;
}
.menu-svg:hover {
    transform: scale(1.3);
}
.menu-svg:active {
    opacity: 0.5;
}

.new-offer {
    z-index: 999;
    position: fixed;
    bottom: 101px;
    right: 20px;
    /*z-index: 3;*/
}

.offer_header {
    display: flex;
    align-items: flex-start;

    flex-direction: column;
}

.offer_body {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #4b4b4b;
}
.offer_body .button_container .btn {
    width: 355px;
    margin-bottom: 40px;
}

.offer_body_item {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.offer_body_item_width {
    white-space: nowrap;
    line-height: 23px;
}
.offer_body_item_centered {
    align-items: flex-start;
}
.offer_body .documents-wrapper {
    margin-top: 28px;
}
.offer_body .documents-wrapper span {
    font-size: 16px;
    color: #888888;
    font-weight: 400;
    margin-bottom: 4px;
}
.offer_body .documents-wrapper div {
    color: #4b4b4b;
    text-decoration: underline !important;
    font-size: 18px;
    margin-bottom: 4px;
    cursor: pointer;
}
.offer_body_item > span {
    font-weight: normal;
    line-height: 25px;
    color: #888888;
    margin-left: 10px;
}

.terms_item {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
}

.button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.btn-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #615ffb;
    outline: none !important;
    transition: 0.1s;
}
.btn-text:active {
    opacity: 60% !important;
    outline: none;
}
.btn-text:hover {
    outline: none;
    transition: 0.3s;
    opacity: 0.7;
}
.btn-text > svg {
}
.PublicOffers-filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.offers-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.new-req-form {
    max-width: 500px;
    margin: 0 auto !important;
}
@media (max-width: 360px) {
    .offer-number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .offer_body_item_width {
        white-space: normal;
    }
}
@media (min-width: 400px) {
    .PublicOffers-filter {
        flex-direction: row;
    }
}
@media (min-width: 765px) {
    .new-offer {
        bottom: 30px;
        z-index: 1000;
    }
    .new-req-form {
    }
    .new-req-form .btn {
        width: 355px;
        align-self: center;
    }
    .new-req-form_btn-container .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
    }
}
