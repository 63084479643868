.GoBack {
    margin-bottom: 15px;
    transition: 0.3s;
}
.GoBack:hover {
    opacity: 0.5;
}
.back_to {
    /* identical to box height */
    color: #888888;
    height: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.back_to_title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-left: 20px;
}
