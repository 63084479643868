.need-help-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 120px;
}

.need-help-container-title {
    display: flex;
    margin-bottom: 70px;
}

.title-help {
    text-align: center;
    font-weight: 700;
    font-size: 48px;
    color: #4B4B4B;
}

.need-help-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.need-help-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
}

.item-pic {
    flex: 0.27;
}

.item-pic-mail {
    flex: 0.5;
}

.need-help-pic {
    width: 4rem;
}

.item-info {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item-info-title {
    font-weight: 700;
    font-size: 20px;
    color: #4B4B4B;
}

.item-info-description {
    font-weight: 400;
    font-size: 14px;
    color: #4B4B4B;
    margin-bottom: 0;
    margin-bottom: 50px;
}

.item-info-chat-link {
    color: #E8A20F;
    cursor: pointer;
}

.item-info-chat-link:hover {
    text-decoration-line: underline;
    color: #E8A20F;
    cursor: pointer;
}

@media screen and (max-width: 640px) {

    .need-help-container {
        margin-bottom: 80px;
    }

    .title {
        font-size: 28px;
    }

    .need-help-item-container {
        flex-direction: column;
        gap: 80px;
    }
}