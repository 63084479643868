.container-interest {
    display: flex;
    flex-direction: row;
    background-color: #615FFB;
    border-radius: 10px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    /*align-items: center;*/
    margin-bottom: 120px;
    /*padding: 53px 95px;*/
}

.item-interest {
    margin-right: 10px;
}
.item-interest:last-child {
    margin-right: 0px;
}

.percent-title {
    color: #FFFFFF;
    font-weight: 900;
    font-size: 48px;
    margin: 35px 0 20px;
    line-height: 40px;
}

.percent-description {
    color: #FFFFFF;
    margin-bottom: 35px;
}

@media screen and (max-width: 850px) {
    .container-interest {
        flex-direction: column;
        margin-bottom: 80px;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 429px) {

    .item-interest {
        margin-right: 0;
    }
    .percent-title {
        line-height: 60px;
    }
}