.notFound-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 20vh;
}
.notFound_title {
    color: #6a62f4;
    font-size: 150px;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 30px;
}
.notFound_subtitle {
    color: #615ffb;
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    margin-top: 52px;
}
.notFound-wrapper > img {
    /*width: 355px;*/
    /*height: 230px;*/
    margin-top: 82px;
    width: 144px;
    height: 131px;
}

@media (min-width: 765px) {
    .notFound_title {
        font-size: 260px;
    }
    .notFound_subtitle {
        /*font-size: 48px;*/
        /*margin-top: -46px;*/
        /*margin-bottom: 50px;*/
    }
    .notFound-wrapper > img {
        /*width: 720px;*/
        /*height: 330px;*/
        /*width: 144px;*/
        /*height: 131px;*/
    }
}
