.benefits-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 120px;
    justify-content: center;
}

.title-benefits-container {
    text-align: center;
}

.title-benefits {
    margin-bottom: 70px;
    font-weight: 700;
    font-size: 48px;
    color: #4B4B4B;
}

.benefits-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.benefits-item {
    width: 26vw;
    /*height: 261px;*/
    background: #615FFB;
    border-radius: 8px;
    padding: 20px;
}

.item-title {
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    margin: 35px 0 15px 0;
}

.item-description {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

@media screen and (max-width: 850px) {
    .title-benefits {
        font-size: 28px;
    }
    .benefits-item-container {
        flex-direction: column;
        align-items: center;
        gap: 40px
    }

    .benefits-item {
        width: 100%;
        max-width: 335px;
    }
}


@media screen and (max-width: 429px) {
    .benefits-container {
        margin-bottom: 80px;
    }

    .title-benefits {
        font-size: 28px;
    }

    .benefits-item-container {
        flex-direction: column;
        align-items: center;
        gap: 40px
    }

    .benefits-item {
        width: 80vw;
    }
}