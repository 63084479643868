.work-with {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 120px;
}

.work-title {
    margin-bottom: 70px;
}

.work-info {
    display: flex;
    flex-direction: row;
    gap: 80px;
}

.work-info-left {
    flex: 1;
}

.work-info-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 100px;
}

.item-left-pic {
    margin-right: 53px;
}

.item-left-pic-coin {
    margin-right: 30px;
}

.item-left-pic-link {
    margin-right: 45px;
}

.become-button {
    display: flex;
    justify-content: center;
}

.work-info-left a {
    display: block;
    width: 100%;
    max-width: 323px;
}

.item-left-info {}

.item-left-header {
    font-weight: 600;
    font-size: 24px;
    color: #4B4B4B;
}

.work-info-right {
    flex: 1;
}

@media screen and (max-width: 850px) {
    .work-info {
        flex-direction: column;
        align-items: center;
    }
}



@media screen and (max-width: 429px) {
    .work-title {
        text-align: center;
    }

    .work-info {
        flex-direction: column;
        align-items: center;
    }

    .work-info-left {
        align-items: center;
    }

    .work-info-item {
        align-items: center;
        /*margin-left: 20px;*/
    }

    .item-left-pic {
        flex: 1;
        margin-right: 35px;
    }

    .item-left-pic-coin {
        margin-right: 18px;
    }

    .item-left-pic-link {
        margin-right: 30px;
    }

    .become-button {
        display: flex;
        justify-content: center;
    }

    .work-info-right {
        display: flex;
        justify-content: center;
    }

    .work-info-right-img {
        width: 90%;
        /* margin-left: 25px; */
    }
}