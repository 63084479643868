.become-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 120px;
}

.partners_form {
    display: flex;
    flex-direction: column;
}

.input {
    /*width: 540px;*/
    width: 100%;
    /*max-width: 540px;*/
    height: 47px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 15px;
    outline-color: #615FFB;
    /*margin-bottom: 20px;*/
}

.ant-form {
    width: 100%;
    /*max-width: 540px;*/
    display: flex;
    align-items: center;
    flex-direction: column;
}

.ant-form-item {
    width: 100%;
    max-width: 540px;
    display: flex;
    justify-content: center;
}

/*.input-not-valid {*/
/*    outline: 1px solid red;*/
/*    width: 540px;*/
/*    height: 47px;*/
/*    border: 1px solid #D9D9D9;*/
/*    border-radius: 4px;*/
/*    padding: 15px;*/
/*    margin-bottom: 20px;*/
/*}*/

input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.become-title {
    margin-bottom: 70px;
    font-weight: 700;
    font-size: 48px;
    color: #4B4B4B;
}

.become-title-container {
    text-align: center;
}

.button-container {
    width: 100%;
    max-width: 323px;
}

@media screen and (max-width: 850px) {
    .become-title {
        font-size: 28px;
    }
}

    @media screen and (max-width: 429px) {
    .become-container {
        margin-bottom: 80px;
    }

    .input {
        height: 47px;
        /*padding: 15px;*/
        margin-bottom: 15px;
    }

    /*.input-not-valid {*/
    /*    outline: 1px solid red;*/
    /*    width: 352px;*/
    /*    height: 47px;*/
    /*    padding: 15px;*/
    /*    margin-bottom: 15px;*/
    /*}*/

    .become-title {
        margin-bottom: 70px;
        font-size: 28px;
    }
}