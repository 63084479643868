@font-face {
    font-family: "Gilroy";
    src: url("assets/fonts/Gilroy-Thin.eot");
    src: local("Gilroy Thin"), local("Gilroy-Thin"),
        url("assets/fonts/Gilroy-Thin.eot?#iefix") format("embedded-opentype"),
        url("assets/fonts/Gilroy-Thin.woff2") format("woff2"), url("assets/fonts/Gilroy-Thin.woff") format("woff"),
        url("assets/fonts/Gilroy-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy Ultra Light";
    src: url("assets/fonts/Gilroy-UltraLight.eot");
    src: url("assets/fonts/Gilroy-UltraLight.eot?#iefix") format("embedded-opentype"),
        url("assets/fonts/Gilroy-UltraLight.woff") format("woff"),
        url("assets/fonts/Gilroy-UltraLight.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy Semibold";
    src: url("assets/fonts/Gilroy-SemiBold.eot");
    src: url("assets/fonts/Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("assets/fonts/Gilroy-SemiBold.woff") format("woff"),
        url("assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

html,
body {
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif !important;
}
input {
    font-size: 16px;
}
select {
    /*-moz-appearance: none;*/
    /*-webkit-appearance: none;*/
    border: none;
    outline: none;
}
.item {
    position: relative;
}

a {
    text-decoration: none;
}
video {
     -webkit-mask-image: -webkit-radial-gradient(white, black);
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
 }
button:active {
    outline: none;
}
button:hover {
    outline: none;
}
button::selection {
    outline: none;
}
button:disabled {
    cursor: none;
}
.hide {
    display: none;
}
.close {
    color: #de4e4f;
    font-size: 13px;
    opacity: 1 !important;
    padding-top: 20px;
    padding-right: 20px;
}
.logo {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1em;
    color: #28253a;
    cursor: pointer;
    transition: 0.2s;
}
.logo:hover {
    opacity: 0.7;
}
.logo:active {
    opacity: 0.5;
}
.btn {
    width: 100%;
    height: 62px;
    background: #615ffb;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.033);
    border-radius: 8px;
    color: #ffffff;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    transition: 0.3s;
    border: none !important;
}
.dowanloadBtn {
}
.btn:active {
    outline: none !important;

    color: #ffffff !important;
    border: none !important;
}

.btn:disabled {
    cursor: initial;
}

.btn:disabled:hover {
    box-shadow: none;
}

.btn:focus {
    box-shadow: none;
}
.btn:hover {
    box-shadow: 0 0 10px 5px rgba(97, 95, 221, 0.3);
    transition: 0.3s;
    color: #ffffff !important;
    outline: none !important;
}
.btn.red {
    background-color: #de4e4f;
}
.btn.green {
    background-color: #5fa588;
}
.btn.red:hover {
    box-shadow: 0 0 10px 5px rgba(222, 78, 79, 0.3);
}
.btn.gray {
    background-color: #aaaaaa;
}
.btn.gray:hover {
    box-shadow: 0 0 10px 5px rgba(170, 170, 170, 0.3);
}
/* Виджит re plain */
/* div#__replain_widget {
    height: 1px;
    width: 0px !important;
} */
/* .app .chat {
    height: 80% !important;
}  */
/* .app div.chat {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: absolute;
    left: -2px !important;
    right: 0px;
    top: auto;
    bottom: -7px !important;
    width: 91% !important;
    height: 88% !important;
    max-width: 100%;
    background-color: #fff;
    border-left: 1px solid #ddd;
    box-shadow: 0 0 7px 0 rgb(0 0 0 / 10%);
    opacity: 1;
    pointer-events: none;
}  */
.title {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 20px;
}
.thin {
    font-weight: 300;
}
.card:first-of-type {
    margin-top: 15px;
}
.card {
    padding: 17px 14px;
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 0.3);
    margin-bottom: 10px;
    border: none !important;
    border-radius: unset !important;
    z-index: 2;
    word-wrap: unset;
    background: #fff;
    transition: 0.3 ease;
}
.card_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    align-items: center;
}
.card:hover {
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 0.6);
    transition: 0.2s ease;
}

.card_info {
    display: flex;
    flex-direction: column;
}
.card_title {
    font-weight: 700;
    font-size: 16px;
}
.card_footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.card_btn-container {
    margin-top: 20px;
}
.card_btn-container .btn.gray {
    margin-bottom: 15px;
}
.card_info_item {
    font-weight: 400;
    font-size: 12px;
    color: #888888;
    display: flex;
}
.card_info_item > span {
    font-weight: 700;
    margin-right: 5px;
}

.circle {
    border-radius: 50%;
    background-color: #de4e4f;
    width: 15px;
    height: 15px;
    display: none;
    transition: 0.4s;
    font-weight: 700;
}
/* Loader */
.Loader {
    min-height: 100vh;
}

/* статусы */

.status-success {
    white-space: nowrap;
    padding: 2px 7px;
    background-color: #e6e5fe;
    font-size: 14px;
    border-radius: 6px;
    text-align: center;
    color: #615ffb;
    box-sizing: content-box;
    min-width: 82px;
}
.status-cancel {
    white-space: nowrap;
    padding: 2px 7px;
    font-size: 14px;
    border-radius: 6px;
    text-align: center;
    background-color: #e4e4e4;
    color: #4d4d4d;
    box-sizing: content-box;
    min-width: 82px;
}
.status-paid {
    white-space: nowrap;
    padding: 2px 7px;
    border-radius: 6px;
    font-size: 14px;
    background-color: #e5f1ec;
    text-align: center;
    color: #5fa588;
    box-sizing: content-box;
    min-width: 82px;
    text-align: center;
}
.status-in-processing {
    white-space: nowrap;
    text-align: center;
    padding: 2px 7px;
    font-size: 14px;
    border-radius: 6px;
    background-color: #ffe3d6;
    color: #ff5300;
    box-sizing: content-box;
    min-width: 82px;
}

.message-ico {
    opacity: 50% !important;
    width: 14px;
    height: 14px;
}
.no-styles {
    animation: none;
    animation-delay: 0;
    animation-direction: normal;
    animation-duration: 0;
    animation-fill-mode: none;
    animation-iteration-count: 1;
    animation-name: none;
    animation-play-state: running;
    animation-timing-function: ease;
    backface-visibility: visible;
    background: 0;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: none;
    background-origin: padding-box;
    background-position: 0 0;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto auto;
    border: 0;
    border-style: none;
    border-width: medium;
    border-color: inherit;
    border-bottom: 0;
    border-bottom-color: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-collapse: separate;
    border-image: none;
    border-left: 0;
    border-left-color: inherit;
    border-left-style: none;
    border-left-width: medium;
    border-radius: 0;
    border-right: 0;
    border-right-color: inherit;
    border-right-style: none;
    border-right-width: medium;
    border-spacing: 0;
    border-top: 0;
    border-top-color: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-style: none;
    border-top-width: medium;
    bottom: auto;
    box-shadow: none;
    box-sizing: content-box;
    caption-side: top;
    clear: none;
    clip: auto;
    color: inherit;
    columns: auto;
    column-count: auto;
    column-fill: balance;
    column-gap: normal;
    column-rule: medium none currentColor;
    column-rule-color: currentColor;
    column-rule-style: none;
    column-rule-width: none;
    column-span: 1;
    column-width: auto;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    cursor: auto;
    direction: ltr;
    display: inline;
    empty-cells: show;
    float: none;
    font: normal;
    font-family: inherit;
    font-size: medium;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    height: auto;
    hyphens: none;
    left: auto;
    letter-spacing: normal;
    line-height: normal;
    list-style: none;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: disc;
    margin: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    opacity: 1;
    orphans: 0;
    outline: 0;
    outline-color: invert;
    outline-style: none;
    outline-width: medium;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    padding: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: auto;
    perspective: none;
    perspective-origin: 50% 50%;
    position: static;
    /* May need to alter quotes for different locales (e.g fr) */
    quotes: "\201C""\201D""\2018""\2019";
    right: auto;
    tab-size: 8;
    table-layout: auto;
    text-align: inherit;
    text-align-last: auto;
    text-decoration: none;
    text-decoration-color: inherit;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    top: auto;
    transform: none;
    transform-style: flat;
    transition: none;
    transition-delay: 0s;
    transition-duration: 0s;
    transition-property: none;
    transition-timing-function: ease;
    unicode-bidi: normal;
    vertical-align: baseline;
    visibility: visible;
    white-space: normal;
    widows: 0;
    width: auto;
    word-spacing: normal;
    z-index: auto;
    /* basic modern patch */
    all: initial;
    all: unset;
}

.date {
    color: #888888;
    font-size: 12px;
    font-weight: 300;
}
.content-wrapper {
    padding: 0 20px;
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
}
/* .status-success {
    padding: 4px 7px;
    border-radius: 6px;

    color: #615ffb;
    box-sizing: content-box;
    min-width: 77px;
} */

.phone_number_input {
    width: 100%;
    height: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 6.5px 11px;
    font-size: 16px;
    outline: none;
    /*font-family: "Inter", sans-serif !important;*/
    font-weight: 400;
}

.phone_number_input::placeholder {
    color: rgba(0, 0, 0, 0.55);
    font-weight: 400;
}

.Offers {
    min-height: 100%;
}

.credit-text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 97.8%;
    color: #615FFB;
    margin: 48px 0 0 0;
}

#lang {
    border: none;
    background: transparent;
}

/*.mobile-container {*/
/*    !*min-height: 730px;*!*/
/*    height: 100vh;*/
/*    display: flex;*/
/*    align-items: flex-end;*/
/*    position: relative;*/
/*    padding-left: 26px;*/
/*    padding-right: 26px;*/
/*    max-width: 400px;*/
/*}*/
.request_body {
    padding-top: 15px;
}

/*.mobile-container h1 {*/
/*    font-family: Gilroy;*/
/*    font-style: normal;*/
/*    font-weight: bold;*/
/*    font-size: 30px;*/
/*    line-height: 36px;*/
/*    color: #28253A;*/
/*}*/

.login {
    border: none;
    background: none;
    margin-bottom: 7px;
}

.login > img {
    width: 20px;
    height: 24px;
}

/* ant mutation */
.ant-spin {
    position: absolute !important;
    left: 0;
    right: 0;
    bottom: 50%;
}
.ant-picker {
    width: 100%;
    border-radius: 4px !important;
}
.ant-modal-content {
    padding-top: 26px;
    padding-bottom: 12px;
    border-radius: 12px !important;
}
.ant-modal {
    /* z-index: 11000; */
}
.ant-modal-wrap {
    z-index: 10000 !important;
}
.ant-modal-mask {
    z-index: 10000 !important;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn:hover {
    background: transparent;
    border: none;
}
.ant-modal-content .ant-btn-primary {
    background-color: #615ffb !important;
    border: none !important;
}
.ant-modal-content .ant-btn {
    border-color: #888888b0;
    background-color: #888888b0;
    color: #fff;
    border-radius: 5px;
    min-width: 149px;
    text-transform: uppercase;
    height: 51px;
}
.ant-modal-content .dowanloadBtn {
    color: #000;
    height: 36px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400 !important;
    background: transparent;
}
.ant-modal-content .dowanloadBtn:hover {
    background: transparent !important;
    color: #000 !important;
    opacity: 0.7%;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
    /* width: 20px !important; */
    background: transparent;
    border: none;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm span.anticon.anticon-delete {
    width: 30px;
    align-self: flex-end;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
    display: flex;
    justify-content: flex-end;
}
.ant-modal-header {
    border-bottom: none !important;
}
.ant-modal-footer {
    border: none !important;
    text-align: center !important;
}
.ant-modal-body {
    display: block;
    padding: 0 24px !important;
}
.ant-modal-body .payment {
    font-weight: bold;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 25px;
    width: 100%;
}
.ant-modal-body .payment .payment-title {
    font-weight: 400;
    margin-right: 30px;
}
.ant-modal-body .payment .payment-item {
    display: flex;
    /* justify-content: space-between; */
    /* max-width: 350px; */
}
.ant-modal-body .payment div {
    margin-bottom: 15px;
}
.ant-modal-body .payment div:last-child {
    margin-bottom: 0;
}
.ant-modal-content .ant-btn:hover {
    opacity: 0.5;
    color: #fff;
    border-color: #888888b0;
    background-color: #888888b0;
}
.ant-modal-content .ant-btn-primary::selection {
    outline: none;
}

.ant-message {
    z-index: 10000000000 !important;
}

.ant-message-custom-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-btn-lg {
    width: 100%;
    height: 62px !important;
    background: #615ffb !important;
    color: #ffffff !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
    border-radius: 8px !important;
}

.ant-input {
    border-radius: 4px !important;
    font-size: 16px !important;
}

textarea.ant-input {
    resize: none;
}
.ant-input-affix-wrapper {
    border-radius: 4px !important;
}
.ant-btn > span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px !important;
    line-height: 24px !important;
}

.ant-btn-circle {
    background: linear-gradient(0deg, #615ffb, #615ffb) !important;
    border-radius: 90px !important;
    border: none !important;
    box-shadow: 0 0 10px 5px rgba(97, 95, 221, 0.3) !important;
    transition: 0.3s;
}
.ant-btn-circle:hover {
    box-shadow: 0 0 10px 5px rgba(97, 95, 221, 0.7) !important;
    opacity: 0.9;
}
.ant-btn-circle .anticon > svg {
    width: 30px;
    height: 30px;
}
.ant-btn-circle:active {
    opacity: 50%;
}

.ant-modal-close-x {
    width: 100px !important;
    overflow: visible;
    z-index: 4;
}
.ant-tooltip {
    z-index: 10002 !important;
}
.ant-tooltip-inner {
    border-radius: 8px !important;
    color: #000000 !important;

    background: #ffffff !important;
}

/* .anticon svg {
    width: 26px;
    height: 26px;
} */
/*Оферта*/
.offerta_body {
    display: flex;
    margin-bottom: 20px;
}
.offerta_number {
    margin-right: 20px;
    min-width: 16px;
}
.offerta_title {
    margin-right: 10px;
    /*min-width: 120px;*/
    width: 120px;
}
.offerta_text {
    max-width: 800px;
}
.offerta_text_items {
    display: flex;
}
.offerta_text_items > span {
    margin-right: 10px;
    min-width: 35px;
}
.framework_Contract_footer_items {
    display: flex;
    min-width: 50%;
    flex-direction: column;
}
.framework_Contract_footer_items > span {
    margin-top: 10px;
}
/* Саб меню */
.sub-menu {
    padding: 20px;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 205px;
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 0.3);
    border-radius: 8px;
    position: absolute;
    font-size: 13px;
    background-color: #fff;
    color: #4b4b4bcc;
    z-index: 100;
    box-sizing: border-box;
    transition: 0.2s;
}
.sub-menu-item {
    margin-bottom: 25px;
    align-items: center;
    border: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: 0.2s;
}
.sub-menu-item:hover .circle {
    display: block;
    transition: 0.4s;
}
.sub-menu-item:last-child {
    margin-bottom: 0;
}
/* Карта */
.serch-container {
    margin-bottom: 32px;
    position: relative;
    display: flex;
}
.map {
    height: 61vh;
    position: relative;
}

.map-gift {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 60px;
    height: 60px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    transition: 300ms;
}

.map-gift:disabled {
    opacity: 0;
    visibility: hidden;
}

.map-gift:hover {
    transform: scale(1.1);
}

.map-gift:hover:disabled {
    transform: scale(1);
}

.map-gift:focus {
    outline: none;
}

.leaflet-container {
    width: 100%;
    height: 100%;
    z-index: 1;
}
.map-serch-selector {
    width: 100%;
    position: absolute;
    z-index: 1001;
    background-color: #ffffff;
    padding: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    top: 33px;
}
.map-serch-selector_option {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px 0;
    font-weight: 700;
}

.main {
    padding-bottom: 100px;
    min-height: 60vh;
}

.marker_title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: space-between;
    color: rgba(75, 75, 75, 0.8);
    margin-bottom: 20px;
    align-items: center;
}
.login_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vh 0 20vh;
}
.leaflet-popup-close-button {
    width: 20px !important;
    height: 20px !important;
}

.leaflet-popup-content-wrapper {
    width: 327px;
}
.ant-btn-icon-only.ant-btn-sm {
    width: 20px !important;
}
@media (max-width: 360px) {
    .ant-modal-footer {
        display: flex;
        flex-direction: column;
    }
    .ant-btn-primary {
        margin-top: 10px;
        margin-left: 0 !important;
    }
    .card_header {
        flex-direction: column;
        gap: 8px;
        align-items: start;
    }
    .leaflet-popup-content-wrapper {
        width: 260px;
    }
    .marker_title {
        font-size: 14px;
    }
}
@media (min-width: 765px) {
    .map-gift:disabled {
        opacity: 0.4;
        cursor: initial;
        visibility: initial;
    }
    .main {
        padding-bottom: 10px;
    }
    .map {
        height: 72vh;
        padding-bottom: 0;
    }
    .serch-container {
        max-width: 400px;
    }
    .card_btn-container .btn.gray {
        margin-bottom: 0;
        margin-right: 32px;
        width: 137px;
    }
    .card_footer {
        flex-direction: row;
    }
    .card_footer .btn {
        width: 262px;
    }
}
