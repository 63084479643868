.menu {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
    z-index: 100;
    height: 82px;
    width: 100%;
    /* transition: 0.1s; */
    bottom: 0;
    /* top: calc(var(--vh, 1vh) * 100 - 82px); */
    background: #ffffff;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.05);
    padding-bottom: env(safe-area-inset-bottom);
    z-index: 1000;
    -webkit-box-align: center;
}

.menu .sub-menu {
    bottom: 89px;
    right: 13px;
}

.menu .item {
    display: flex;
    /*flex-direction: column;*/
    color: #000000;
    align-items: center;
    /*margin-bottom: 23px;*/
}

.sub-menu .item {
    margin-bottom: 23px;
}

.menu .item:last-child {
    margin-bottom: 0px;
}

.menu .item>span {
    margin-left: 20px;
}

.menu .item>svg {
    min-width: 30px;
}

.menu>.menu_item:hover .sub-menu {
    display: flex;
}

.menu>a.item {
    padding: 10px !important;
}

@media (min-width: 765px) {
    .menu {
        display: none;
    }
}