footer.footer {
    background: linear-gradient(#615ffb, #161839);
    padding-bottom: 72px;
}
.footer-wrapper {
    padding: 35px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 220px;
}
.footer-wrapper .logo {
    color: inherit;
    font-size: 18px;
    margin-bottom: 17px;
}
.footer-wrapper .title {
    color: inherit;
    font-size: 16px;
    line-height: 19px;
}
.footer-wrapper nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.footer-wrapper nav span {
    padding: 5px 0;
    min-width: 90px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.footer-wrapper nav span svg {
    margin-left: 10px;
}
.footer-wrapper nav span svg polygon,
.footer-wrapper nav span svg line,
.footer-wrapper nav span svg polyline,
.footer-wrapper nav span svg path,
.footer-wrapper nav span svg mesh,
.footer-wrapper nav span svg rect,
.footer-wrapper nav span svg circle,
.footer-wrapper nav span svg ellipse {
    fill: #fff;
    width: 15px;
}
.footer-wrapper .title {
    font-size: 18px !important;
    line-height: 22px !important;
    margin-bottom: 20px !important;
}
.footer-wrapper .social {
    display: flex;
    flex-direction: column;
}
.social_item {
    margin-bottom: 12px;
}
.social_item:last-of-type {
    margin-bottom: 0;
}
.navigation {
    color: #fff !important;
}
@media (min-width: 1000px) {
    .footer-wrapper {
        max-width: 318px;
    }
}
