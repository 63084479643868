.credit {
  margin: 48px 0 62px 0;
}

.credit-wrapper {
  margin: 33px 0 48px 0;
}

.credit-cart-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 37px 0;
  position: relative;
}

.credit-divider {
  border-bottom: 1px solid rgba(75, 75, 75, 0.1);
}

.credit-btn {
  width: 130px;
  margin: 0 0 0 16px;
}

.credit-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: 300ms;
  margin: 0;
}

.credit-form-active {
  opacity: 1;
  visibility: visible;
  height: 62px;
  margin: 48px 0 0 0;
}

.credit-input-phone,
.credit-input-money{
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  outline: none;
  width: 382px;
  padding: 8px 12px;
  margin: 0 62px 0 0;
}

.credit-input-phone::placeholder,
.credit-input-money::placeholder {
  color: rgba(0, 0, 0, 0.85);
}

.credit-input-money {
  padding: 8px 12px 8px 30px;
  position: relative;
}

.credit-input-money-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
}

.credit-input-money-wrapper::before {
  content: '₽';
  position: absolute;
  font-family: 'Gilroy Ultra Light', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.45);
  top: 7px;
  left: 15px;
  z-index: 2;
}

.credit-percent {
  font-family: 'Gilroy Semibold', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 97.8%;
  margin: 0;
}

.credit-money {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 97.8%;
  margin: 0;
}

.credit-input:not(:last-of-type) {
  margin: 0 62px 0 0;
}

.credit-input-error {
  border: 1px solid #DE4E4F;
}

.credit-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.credit-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  position: relative;
  cursor: pointer;
}

.credit-checkbox + label::before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #615FFB;
  border-radius: 1px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  transition: 500ms;
}

.credit-checkbox + label::after {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 1px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  transition: 500ms;
  position: absolute;
  left: 6px;
}

.credit-checkbox:checked + label::after {
  border-color: #615FFB;
  background-color: #615FFB;
  background-size: 12px;
}

.credit-checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: #615FFB;
}

@media (max-width: 992px) {
  .credit-input-phone,
  .credit-input-money {
    width: 250px;
  }
}

@media (max-width: 765px) {
  .credit-input-phone,
  .credit-input-money {
    width: 100%;
    height: 62px;
    margin: 0 0 20px 0;
    font-size: 20px;
  }

  .credit-input-phone::placeholder,
  .credit-input-money::placeholder {
    font-size: 20px;
  }

  .credit-input-money-wrapper::before {
    font-size: 20px;
    top: 19px;
  }

  .credit-cart-wrapper {
    margin: 0 0 60px 0;
  }

  .credit-btn {
    width: 100%;
    margin: 0;
    height: 62px;
  }

  .credit-percent {
    position: absolute;
    bottom: -30px;
  }

  .credit-money {
    position: absolute;
    left: 80px;
    bottom: -30px;
  }

  .credit-form {
    flex-direction: column;
  }

  .credit-form-active {
    height: 100%;
  }
}