.login-content {
  width: 364px;
}

.login-content-title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 50px;
}

.login-content-text {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #4B4B4B;
  margin: 28px 0 37px;
}

.btn_container {
  display: flex;
  justify-content: space-between;
}

.auth_btn {
  width: 168px;
}

@media (max-width: 380px) {
  .login-content {
    width: 280px;
  }

  .login-content-title {
    font-size: 36px;
  }

  .btn_container {
    display: flex;
    flex-direction: column;
  }

  .auth_btn {
    margin: 0 auto 10px;
  }
}