.content-wrapper {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-wrapper:last-child {
    align-items: flex-start;
}

@media screen and (max-width: 429px) {
    .content-wrapper {
        max-width: 428px;
    }
}