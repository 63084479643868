.Wallet_title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 23px;
}
.Wallet_title > img {
    margin-right: 15px;
    height: 24px;
    width: 24px;
}
.Wallet_info {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 39px;
}
.Wallet_info .column {
    display: flex;
    flex-direction: column;
    margin-right: 52px;
}
.Wallet_info .column:last-child {
    margin-right: unset;
}
.Wallet_info_title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
}
.Wallet_info-wrapper {
    display: flex;
    flex-direction: column;
}
@media (min-width: 765px) {
    .Wallet .btn {
        max-width: 331px !important;
    }
    .Wallet a {
        align-self: flex-end;
    }

    .Wallet_info-wrapper {
        flex-direction: row;
        justify-content: space-between;
    }
}
