.button-container {
    display: flex;
    justify-content: center;
}

.button {
    width: 100%;
    height: 75px;
    background: #615FFB;
    backdrop-filter: blur(6px);
    border-radius: 8px;
    text-align: center;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.button:hover {
    box-shadow: 0 0 10px 5px rgba(97, 95, 221, 0.3);
    transition: 0.3s;
}

@media (min-width: 850px) {
    .button-container {
        justify-content: left;
    }
}