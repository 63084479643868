.subscription_card_items {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    font-style: normal;
    font-weight: normal;

    line-height: 16px;
}
.subscription_card_item {
    display: flex;
    align-items: center;
    font-size: 14px;
    min-width: 128px;
    margin-bottom: 10px;
    line-height: 14px;
}
.subscription_card_item > img {
    margin-right: 15px;
    width: 7px;
    height: 7px;
    margin-top: 3px;
}
.subscription_card_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.subscription_card {
    font-weight: 500;
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 0.3);
    padding: 25px;
    /*margin-bottom: 20px;*/
    background: #615FFB;
    border-radius: 20px;
    color: #ffffff;
    width: 100%;
    margin-right: 34px;
    margin-bottom: 10px;
}
.subscription_subtitle_container {
    margin-bottom: 36px;
    margin-top: 24px;
}
.subscription_card_content {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
}
.subscription_card_title>span {
    font-weight: 400;
}
.subscription_card_price {
    font-weight: 400;
    font-size: 16px;
}
.subscription_card_title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    padding-bottom: 0px;
    line-height: 24px;
    margin-right: 50px;
    /*margin-bottom: 29px;*/
}
.subscription_title_container {
    display: flex;
    justify-content: space-between;
}
.subscription_card_subtitle {
    margin-bottom: 31px;
    font-weight: 700;
    font-size: 18px;
}
.subscription_card_chose {
    display: flex;
    align-items: center;
    margin-top: 34px;
}
.subscription_card_chose>span {
    margin-left: 24px;
}
.about_items_container {
    display: flex;
    margin-bottom: 14px;
    align-items: baseline;
}
.about_items_container>span {
    margin-left: 14px;
    max-width: 140px;
}
.subscription_card_about {
    display: flex;
    justify-content: space-between;
    margin-top: 31px;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: 16px ;
    max-width: 400px;
}
.chose_btn{
    width: 100%;
    height: 62px;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.033);
    border-radius: 8px;
    color: #ffffff;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    transition: 0.3s;
    border: none !important;
}
.subscription_about .column {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    padding-top: 15px;
}
.subscription_card .btn {
    max-width: 196px;
    background: #fff;
    height: 62px;
    margin-top: 10px;
}
.subscription_about .column:last-child {
    margin-bottom: 0;
}

@media (min-width: 765px) {
    .subscription_card {
        min-width: 306px;
    }
    .subscription_about_block {
        margin-right: 10px;
    }
    .subscription_card_container {
        flex-direction: row;
    }
    .subscription_card_content {
        flex-direction: column;

    }
    .subscription_card {
        flex-direction: column;
        justify-content: space-between;
    }
    .subscription_card_about {
        display: block;
        margin-top: 52px;
    }

    .subscription_subtitle_container {
        margin-bottom: 0;
    }
    .subscription_card_title {
        font-size: 32px;
    }
    .subscription_card_time {
        width: 90px;
        font-size: 14px;
        font-weight: 400;
    }
    .subscription_card_subtitle {
        font-size: 20px;
        margin-top: 18px;
        margin-bottom: 34px;
    }
    .subscription_card .chose_btn {
        margin-top: 5px;
        width: 196px;
    }
    .subscription_card .column {
        margin-right: 30px;
    }
}
